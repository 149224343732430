<template>
    <div>
        <MODAL
            :width="900"
            :visible="visible"
            :class="namespaceClass('watchlist-management')"
            ref="watchlistManagement"
            :title="initedLabels.watchlist"
            :skin="skin"
        >
            <button
                slot="content-header"
                :class="[
                    'mds-button mds-button--icon-only',
                    namespaceClass('watchlist-management-content-button')
                ]"
                data-mds-modal-close
                :disabled="!selectArray.length"
                @click="handleButton('open')"
            >
                <svg class="mds-icon mds-button__icon mds-button__icon--left">
                    <use :xlink:href="mdsIcon('folder-close')">
                        <title>Open</title>
                    </use>
                </svg>
                <span class="mds-button__text">Open</span>
            </button>
            <button
                slot="content-header"
                :class="[
                    'mds-button mds-button--icon-only',
                    namespaceClass('watchlist-management-content-button')
                ]"
                data-mds-modal-close
                :disabled="!selectArray.length || hasReadonlySelect"
                @click="handleButton('delete')"
            >
                <svg class="mds-icon mds-button__icon mds-button__icon--left">
                    <use :xlink:href="mdsIcon('trash')">
                        <title>Delete</title>
                    </use>
                </svg>
                <span class="mds-button__text">Delete</span>
            </button>
            <button
                slot="content-header"
                :class="[
                    'mds-button mds-button--icon-only',
                    namespaceClass('watchlist-management-content-button')
                ]"
                data-mds-modal-close
                @click="handleButton('import')"
            >
                <svg class="mds-icon mds-button__icon mds-button__icon--left">
                    <use :xlink:href="mdsIcon('import')">
                        <title>Import</title>
                    </use>
                </svg>
                <span class="mds-button__text">Import</span>
            </button>
            <button
                slot="header-actions"
                class="mds-button mds-button--icon-only"
                data-mds-modal-close
                @click="closeManagement()"
            >
                <svg class="mds-icon mds-button__icon mds-button__icon--left">
                    <use :xlink:href="mdsIcon('remove')">
                        <title>Close</title>
                    </use>
                </svg>
            </button>
            <span class="mds-modal__tips" slot="content">{{
                initedLabels.infoTips
            }}</span>
            <div :class="namespaceClass('grid-container')" slot="content">
                <markets-ui-grid
                    :skin="skin"
                    :option="manageMent.gridOption"
                    :columns="manageMent.gridColumns"
                    :rows="watchlistList"
                    :hover="manageMent.gridHover"
                    @hover-menu-click="clickHoverMenu"
                    @rows-selected-changed="selectedChanaged"
                >
                </markets-ui-grid>
            </div>
        </MODAL>
        <markets-ui-dialog
            @click="clickDialog"
            :cancel="dialog.cancel"
            :resolve="dialog.resolve"
            :visible="dialog.visible"
            :message="dialog.message"
            action-required
            :skin="skin"
        >
            <form
                class="mds-form mds-form--medium-layout"
                onsubmit="return false;"
                v-if="isRename"
                slot="supplemental-content"
            >
                <div
                    class="mds-form__field-group"
                    v-for="item in dialog.inputs"
                    :key="item.id"
                >
                    <label class="mds-form__label" :for="item.id">{{
                        item.label
                    }}</label>
                    <input
                        class="mds-form__input"
                        type="text"
                        v-model="item.value"
                        :disabled="isReadOnly"
                        @focus="onFocus"
                        ref="inputName"
                    />
                    <span
                        class="mds-form__field-error"
                        id="first-name-error"
                        role="alert"
                        v-if="!!dialog.errorMsg"
                    >
                        <span
                            class="mds-form__field-error-text"
                            :class="namespaceClass('error-message')"
                        >
                            {{ dialog.errorMsg }}
                        </span>
                    </span>
                </div>
            </form>
        </markets-ui-dialog>
    </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import labels from '../../assets/metadata/label.json';
import utils, { sortBy, trim } from '../../util/';
import { validateWatchlistName } from '../../common/';
import MODAL from '../ui/modal';
export default {
    name: 'Watchlist-Manager',
    components: { MODAL },
    data() {
        return {
            visible: true,
            manageMent: {
                gridHover: {
                    width: 150,
                    menu: [
                        {
                            id: 'rename',
                            name: 'Rename',
                            click: true
                        }
                    ]
                },
                gridOption: {
                    frozenColumn: 1,
                    layout: 'mbg',
                    autoHeight: false
                },
                gridColumns: [
                    {
                        id: 'checkbox',
                        dataType: 'checkbox',
                        align: 'cneter',
                        sortable: false
                    },
                    {
                        id: 'name',
                        name: 'Name',
                        dataType: 'tree'
                    },
                    {
                        id: 'lastModifiedDate',
                        name: 'Last Modified Date',
                        dataType: 'date',
                        width: 200
                    },
                    {
                        id: 'createdDate',
                        name: 'Created Date',
                        dataType: 'date',
                        width: 200
                    }
                ],
                dataModel: []
            },
            dialog: {
                visible: false,
                errorMsg: '',
                message: '',
                inputs: [],
                reject: {},
                actionInfo: {},
                cancel: {
                    label: 'Cancel'
                },
                resolve: {
                    label: 'Save'
                }
            },
            selectArray: [],
            isReadOnly: true
        };
    },
    created() {
        this.$eventBus.$on(this.$EVENTS.SHOW_WATCHLISTMANGER, () => {
            this.visible = true;
        });
    },
    computed: {
        ...mapState('watchlist', ['watchlists']),
        ...mapGetters('settings', ['skin']),
        initedLabels() {
            return labels.en;
        },
        isRename() {
            return this.dialog.actionInfo.action === 'rename';
        },
        isDelete() {
            return this.dialog.actionInfo.action === 'delete';
        },
        watchlistList() {
            const readonlyList = [];
            const defaultList = [];
            this.watchlists.forEach(item => {
                if (!item.createdDate) {
                    item.createdDate = this.getCurrentDate();
                }
                if (!item.lastModifiedDate) {
                    item.lastModifiedDate = this.getCurrentDate();
                }
                if (item.id.indexOf('readonly') > -1) {
                    readonlyList.push(item);
                } else {
                    defaultList.push(item);
                }
            });
            defaultList.sort(sortBy('name', true));

            return readonlyList.concat(defaultList);
        },
        hasReadonlySelect() {
            const isReadonly = this.selectArray.some(item => {
                return item.id.indexOf('readonly') > -1;
            });
            return isReadonly;
        }
    },
    methods: {
        ...mapActions('watchlist', ['updateWatchlist', 'deleteWatchlists']),
        clickHoverMenu(event) {
            this.isReadOnly = false;
            const rowItem = event.row;
            const target = {
                name: rowItem.name,
                id: rowItem.id,
                tickers: rowItem.tickers
            };
            this.dialog.actionInfo = {
                action: 'rename',
                target: target
            };
            this.dialog.errorMsg = '';
            this.dialog.visible = true;
            this.dialog.inputs = [
                {
                    id: 'rename',
                    label: 'Watchlist Name',
                    value: target.name
                }
            ];
            this.dialog.message = 'Please enter a name for the watchlist';
            if (rowItem.id.indexOf('readonly') > -1) {
                this.isReadOnly = true;
                /* eslint-disable */
                this.dialog.message =
                    "This watchlist is readonly, can't rename name";
                /* eslint-enable */
                this.dialog.resolve = {
                    label: 'Save',
                    disabled: true
                };
            } else {
                this.dialog.resolve = {
                    label: 'Save'
                };
            }
            this.$nextTick(() => {
                this.$refs['inputName'][0].focus();
            });
        },
        clickDialog(type) {
            if (type === 'resolve') {
                if (this.isDelete) {
                    this._deleteWatchlists();
                } else if (this.isRename) {
                    const name = trim(
                        utils.find(this.dialog.inputs, item => {
                            return item.id === 'rename';
                        }).value
                    );
                    const errorMsg = validateWatchlistName(
                        name,
                        this.watchlists
                    );
                    this.dialog.errorMsg = errorMsg;
                    if (!errorMsg) {
                        this._renameWatchlist();
                    }
                }
            } else if (type === 'cancel') {
                this.dialog.visible = false;
            }
        },
        _deleteWatchlists() {
            const idArry = this.selectArray.map(item => {
                return item.id;
            });
            this.$requestManager.add(
                () => this.deleteWatchlists(idArry),
                'deleteWatchlists'
            );
            this.dialog.visible = false;
            this.selectArray = [];
        },
        _renameWatchlist() {
            const updateInfo = {
                id: this.dialog.actionInfo.target.id,
                name: this.dialog.inputs[0].value,
                tickers: this.dialog.actionInfo.target.tickers
            };
            this.updateWatchlist(updateInfo);
            this.dialog.visible = false;
        },
        selectedChanaged(selectedItems) {
            this.selectArray = selectedItems.map(item => {
                return {
                    id: item.id,
                    name: item.name,
                    tickers: item.tickers
                };
            });
        },
        closeManagement() {
            this.visible = false;
        },
        getCurrentDate() {
            const date = new Date();
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            if (month < 10) {
                month = '0' + month;
            }
            if (day < 10) {
                day = '0' + day;
            }
            const nowDate = year + '-' + month + '-' + day;
            return nowDate;
        },
        handleButton(type) {
            if (type === 'open' && this.selectArray) {
                this.$watchlistManager.trigger(
                    'openWatchlists',
                    this.selectArray
                );
                this.closeManagement();
            } else if (type === 'delete' && this.selectArray) {
                const target = {
                    name: this.selectArray[0].name,
                    id: this.selectArray[0].id,
                    tickers: this.selectArray[0].tickers
                };
                this.dialog.message = `Do you want to delete "${target.name}" in your saved watchlist?`;
                if (this.selectArray.length > 1) {
                    target.name = `${this.selectArray.length} items`;
                    this.dialog.message = `Do you want to delete the ${target.name}?`;
                    const idArr = this.selectArray.map(arr => {
                        return arr.id;
                    });
                    target.id = idArr;
                }
                this.dialog.actionInfo = {
                    action: 'delete',
                    target: target
                };

                this.dialog.resolve = {
                    label: 'Delete'
                };
                this.dialog.visible = true;
            } else if (type === 'import') {
                this.$eventBus.$emit(this.$EVENTS.SHOW_IMPORTFILE);
                this.closeManagement();
            }
        },
        onFocus(e) {
            e.target.select();
        }
    }
};
</script>
